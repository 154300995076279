<template>
  <div class="keyboard-box" @touchmove.prevent="() => false" v-if="visiable">
    <div class="cover" @click="onClose"></div>
    <div :class="{'keyboard': true, 'on': show}">
      <div class="top-show">
        <div class="result">
          <h1 v-if="result">{{ result }}</h1>
          <span v-else>请输入数值</span>
        </div>

        <div class="close" @click="onClose">
          <DownSquareOutlined />
        </div>
      </div>

      <div class="keyboard-body">
        <div class="item" @click="key(1)">1</div>
        <div class="item" @click="key(2)">2</div>
        <div class="item" @click="key(3)">3</div>
        <div class="item del" @click="del"><icon-font type="icon-tuige" /></div>
        <div class="item" @click="key(4)">4</div>
        <div class="item" @click="key(5)">5</div>
        <div class="item" @click="key(6)">6</div>
        <div class="item clear" @click="clear">清空</div>
        <div class="item" @click="key(7)">7</div>
        <div class="item" @click="key(8)">8</div>
        <div class="item" @click="key(9)">9</div>
        <div class="item enter">
          <div class="enter-btn" @click="onOk">确定</div>
        </div>
        <div class="item"></div>
        <div class="item" @click="key(0)">0</div>
        <div class="item" @click="point">.</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { DownSquareOutlined } from '@ant-design/icons-vue'

export default defineComponent({
  components: {
    DownSquareOutlined
  },
  props: {
    allowPoint: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    const result = ref('')
    const hasPoint = ref(false)
    const visiable = ref(false)
    const show = ref(false)

    return {
      result,
      hasPoint,
      visiable,
      show
    }
  },

  created () {
    this.$watch('visiable', e => {
      if (e) {
        setTimeout(() => {
          this.show = true
        }, 50)
      }
    })
  },

  methods: {
    open (num) {
      this.result = num + ''
      if (this.result.indexOf('.') > -1) {
        this.hasPoint = true
      } else {
        this.hasPoint = false
      }

      this.visiable = true
    },
    key (number) {
      this.result += number
      this.onChnage()
    },

    point () {
      if (!this.allowPoint) {
        return
      }
      if (!this.hasPoint) {
        this.result += '.'
        this.hasPoint = true
        this.onChnage()
      }
    },

    del () {
      const arr = this.result.split('')
      const point = arr.splice(-1, 1)
      if (point[0] === '.') {
        this.hasPoint = false
      }
      this.result = arr.join('')
      this.onChnage()
    },

    clear () {
      this.result = ''
      this.$emit('clear', 0)
    },

    onChnage () {
      this.$emit('change', Number(this.result))
    },

    onOk () {
      this.$emit('ok', Number(this.result))
      this.onClose()
    },

    onClose () {
      this.$emit('close')
      this.show = false
      this.visiable = false
    }

  }
})
</script>

<style lang="less" scoped>
.keyboard-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .cover{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
  .keyboard {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    max-width: 750px;
    background: #fff;
    overflow: hidden;
    border-top: 1px solid #bababa;
    transition: all .5s ease;
    z-index: 1;
    .top-show {
      background: #ffffff;
      font-size: 0.28rem;
      padding: 0 0.3rem;
      line-height: 0.76rem;
      height: 0.76rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .result {
        width: 6rem;
        height: 100%;
        position: relative;
        h1 {
          margin: 0;
          color: #333;
          position: absolute;
          right: 0;
          min-width: 6rem;
        }
        span {
          color: #666;
        }
      }

      .close {
        width: 0.76rem;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.4rem;
        color: #333;
      }
    }

    .keyboard-body {
      display: flex;
      flex-wrap: wrap;
      background: #d3d1dc;
      padding-top: 0.05rem;
      padding-bottom: 0.05rem;
      padding-left: 0.03rem;
      position: relative;
      .item {
        width: 1.7rem;
        height: 1.1rem;
        border-radius: 0.1rem;
        background: #ffffff;
        margin: 0.05rem 0.08rem;
        text-align: center;
        line-height: 1.1rem;
        font-size: 0.46rem;
        border-bottom: 1px solid #747475;

        &.enter {
          position: relative;
          .enter-btn {
            width: 100%;
            height: 2.3rem;
            line-height: 2.3rem;
            position: absolute;
            top: 0;
            left: 0;
            background: #a8b0bd;
            border-radius: 0.1rem;
            border-bottom: 1px solid #747475;
            font-size: 0.3rem;
          }
        }

        &.del {
          background: #a8b0bd;
          font-size: 0.48rem;
        }

        &.clear {
          background: #a8b0bd;
          font-size: 0.3rem;
        }

        &:active {
          background: #a8b0bd;

          &.del,
          &.clear {
            background: #fff;
          }
          &.enter {
            .enter-btn {
              background: #fff;
            }
          }
        }
      }
    }

    &.on{
      bottom: 0;
    }
  }
}
</style>
